import {
  ISearchRequest,
  ISearchRequestPaging,
  ISearchResponse,
  ISearchResponseTotals,
  SearchDocumentType,
} from '@wix/client-search-sdk';
import { getVisibleCollections } from '../../../../lib/productFacets';
import {
  convertProductFacetsRequestParamsToFilter,
  extractProductFacetsFromSearchResponse,
  hasAnySelectedProductFacets,
} from '../../../SearchResults/Widget/platform/products';

/**
 * NOTE: Not sure that this function should be in this module
 */
export function getAbsoluteDocumentIndex(
  paging: ISearchRequestPaging,
  indexOnCurrentPage: number,
): number {
  if (indexOnCurrentPage < 0) {
    return indexOnCurrentPage;
  }
  const { page, pageSize } = paging;
  const numberOfPreviousResults = page > 1 ? (page - 1) * pageSize : 0;
  return numberOfPreviousResults + indexOnCurrentPage;
}

export function getBIDocumentType(
  documentType?: SearchDocumentType,
): SearchDocumentType {
  return documentType || SearchDocumentType.All;
}

export function getBITotals(totals: ISearchResponseTotals | undefined): string {
  if (!totals) {
    return '0';
  }

  return JSON.stringify(totals);
}

export function getBIAvailableFacets(
  searchRequest: ISearchRequest,
  searchResponse: ISearchResponse,
) {
  if (searchResponse.facets.length === 0) {
    return;
  }

  const allFacets = extractProductFacetsFromSearchResponse(searchResponse);
  const selectedFacets = convertProductFacetsRequestParamsToFilter(
    searchRequest,
  );

  const availableFacets = {
    ...allFacets,
    collections: getVisibleCollections(
      allFacets.collections,
      selectedFacets.collections ?? [],
    ),
  };

  return JSON.stringify(availableFacets);
}

export function getBISelectedFacets(searchRequest: ISearchRequest) {
  const selectedFacets = convertProductFacetsRequestParamsToFilter(
    searchRequest,
  );
  return hasAnySelectedProductFacets(selectedFacets)
    ? JSON.stringify(selectedFacets)
    : undefined;
}
