import {
  IPlatformAPI,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types';

import { SearchDocumentType } from '@wix/client-search-sdk';
import initSchemaLogger, { Logger } from '@wix/bi-logger-wix-search-platform';

import {
  createBICorrelationId,
  BICorrelationIdKey,
  BICorrelationIdStore,
} from './correlationId';
import { SuggestionItems } from './types';
import { getSuggestionsStats } from './getSuggestionsStats';

const correlationIdTimeoutInMilliseconds = 30 * 1000;

export function createSearchPlatformBiLogger(
  platformAPIs: IPlatformAPI,
  wixCodeApi: IWixAPI,
  reportError: (error: Error) => void,
) {
  const biLogger: Logger = initSchemaLogger(platformAPIs.biLoggerFactory?.())();
  const correlationIdStore = new BICorrelationIdStore(
    platformAPIs,
    reportError,
  );

  let correlationIdLastUsedAt: number | undefined;
  const shouldGenerateNewCorrelationId: () => boolean = () => {
    return (
      !correlationIdLastUsedAt ||
      Date.now() - correlationIdLastUsedAt > correlationIdTimeoutInMilliseconds
    );
  };

  const generateCorrelationIdIfNeeded = () => {
    if (shouldGenerateNewCorrelationId()) {
      const correlationId = createBICorrelationId();
      correlationIdStore.set(
        BICorrelationIdKey.SearchSuggestions,
        correlationId,
      );
      correlationIdStore.set(BICorrelationIdKey.SearchSubmit, correlationId);
    }
    correlationIdLastUsedAt = Date.now();
  };

  return {
    searchSubmit: (params: { isDemoContent: boolean; searchQuery: string }) => {
      generateCorrelationIdIfNeeded();

      biLogger.searchBoxSearchSubmit({
        correlationId: correlationIdStore.get(BICorrelationIdKey.SearchSubmit),
        isDemo: params.isDemoContent,
        target: params.searchQuery,
      });
    },

    resetRequestCorrelationId: () => {
      correlationIdLastUsedAt = undefined;
    },

    searchBoxSuggestionsRequestStarted: (startParams: {
      searchQuery: string;
    }) => {
      generateCorrelationIdIfNeeded();
      const startTime = Date.now();

      const commonProps = {
        correlationId: correlationIdStore.get(
          BICorrelationIdKey.SearchSuggestions,
        ),
        target: startParams.searchQuery,
      };

      biLogger.searchBoxSuggestionsRequestStarted(commonProps);

      return function searchBoxSuggestionsRequestFinished(finishParams: {
        success: boolean;
        error?: string;
        suggestions: SuggestionItems;
      }) {
        const loadingDuration = Date.now() - startTime;

        if (finishParams.success) {
          const {
            resultCount,
            resultsArray,
            documentIds,
          } = getSuggestionsStats(finishParams.suggestions);

          // searchBox.suggestLoad.finished 99:242
          // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/242?artifactId=com.wixpress.wix-search-platform
          biLogger.searchBoxSuggestionsRequestFinished({
            ...commonProps,
            documentIds,
            loadingDuration,
            resultCount,
            resultsArray,
            success: true,
          });
        } else {
          // searchBox.suggestLoad.finished 99:242
          // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/242?artifactId=com.wixpress.wix-search-platform
          biLogger.searchBoxSuggestionsRequestFinished({
            ...commonProps,
            error: finishParams.error,
            loadingDuration,
            success: false,
          });
        }
      };
    },

    searchBoxSuggestionClick: (params: {
      title: string;
      url: string;
      searchQuery: string;
      index: number;
      documentType: string;
      suggestions: SuggestionItems;
    }) => {
      const { resultsArray } = getSuggestionsStats(params.suggestions);
      const correlationId = correlationIdStore.get(
        BICorrelationIdKey.SearchSuggestions,
      );

      const clickedSuggestion = params.suggestions.find(
        (s) => s.url === params.url,
      );

      // 99:243 searchBox.suggestResults.click
      // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/243?artifactId=com.wixpress.wix-search-platform
      biLogger.searchBoxSuggestionClick({
        correlationId,
        documentId: clickedSuggestion?.id,
        documentType: params.documentType,
        pageUrl: params.url,
        resultsArray,
        searchIndex: params.index,
        target: params.searchQuery,
        resultClicked: params.title,
      });
    },

    searchBoxSuggestionSearchAllClick: (params: { searchQuery: string }) => {
      biLogger.searchBoxSuggestionShowAllClick({
        correlationId: correlationIdStore.get(
          BICorrelationIdKey.SearchSuggestions,
        ),
        // NOTE: what to do if there is only one tab? (so no All tab)
        documentType: SearchDocumentType.All,
        target: params.searchQuery,
      });
    },

    searchBoxSuggestionShowAllClick: (params: {
      searchQuery: string;
      documentType: string;
    }) => {
      biLogger.searchBoxSuggestionShowAllClick({
        correlationId: correlationIdStore.get(
          BICorrelationIdKey.SearchSuggestions,
        ),
        documentType: params.documentType,
        target: params.searchQuery,
      });
    },
    searchBoxFocused: (params: { isDemo: boolean }) => {
      biLogger.searchBoxFocused({
        isDemo: params.isDemo,
      });
    },
  };
}
