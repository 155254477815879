import {
  ISearchRequest,
  ClientSearchSDK,
  ISearchResponseTotals,
  ISampleResponse,
  SearchDocumentType,
  ISampleResponseSample,
  IErrorResponse,
} from '@wix/client-search-sdk';

const SAMPLES_SIZE = {
  MOBILE: 4,
  DESKTOP: 3,
};

export const getSamplesResponse = (
  searchRequest: ISearchRequest,
  shouldShowSamples: boolean,
  shouldLoadTotals: boolean,
  searchSDK: ClientSearchSDK,
  isMobile: boolean,
  searchResponseTotals: ISearchResponseTotals,
) => {
  const samplesPromise =
    shouldShowSamples || shouldLoadTotals
      ? searchSDK.getSample({
          query: searchRequest.query,
          limit: isMobile ? SAMPLES_SIZE.MOBILE : SAMPLES_SIZE.DESKTOP,
        })
      : Promise.resolve({ results: [] });

  return Promise.all([
    shouldLoadTotals
      ? samplesPromise.then(
          (samplesResponse: ISampleResponse | IErrorResponse) => {
            if ('isError' in samplesResponse) {
              return samplesResponse;
            }
            const totalResults = samplesResponse.results.reduce(
              (sum, { total }) => sum + total,
              0,
            );
            return samplesResponse.results.reduce(
              (
                totals: ISearchResponseTotals,
                { documentType, total }: ISampleResponseSample,
              ) => {
                totals[documentType] = total;
                return totals;
              },
              {
                [SearchDocumentType.All]: totalResults,
              },
            );
          },
        )
      : searchResponseTotals,
    samplesPromise,
  ]);
};
