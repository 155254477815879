import {
  IEncodedLocationSearchRequest,
  SearchQueryParams,
  IDecodeSearchParams,
  IEncodeSearchParams,
} from './location.types';
import { encodeSearchRequest } from './params/encodeSearchRequest';
import { decodeSearchRequest } from './params/decodeSearchRequest';

const PATH_PARAM_VALUE_DELIMITER = '-';

export const decodeParams: IDecodeSearchParams = (location) => {
  // First item in location path is our search results page name
  const path = location.path.slice(1);

  const pathParams = path.reduce<IEncodedLocationSearchRequest>(
    (result, param) => {
      const [key, ...value] = param.split(PATH_PARAM_VALUE_DELIMITER);

      return {
        ...result,
        [key]: value.join(PATH_PARAM_VALUE_DELIMITER),
      };
    },
    {},
  );

  return decodeSearchRequest({
    ...location.query,
    ...pathParams,
  });
};

const PATH_PARAMS = [
  SearchQueryParams.Query,
  SearchQueryParams.DocumentType,
  SearchQueryParams.Page,
  SearchQueryParams.SortBy,
];

const QUERY_PARAMS = [SearchQueryParams.Price, SearchQueryParams.Collections];

export const encodeParams: IEncodeSearchParams = (searchRequest, location) => {
  const params = encodeSearchRequest(searchRequest);
  const paramsList = Object.keys(params) as SearchQueryParams[];

  const pathParams = paramsList
    .filter((param) => PATH_PARAMS.includes(param))
    .sort((a, b) => {
      return PATH_PARAMS.indexOf(a) - PATH_PARAMS.indexOf(b);
    })
    .map((param) => `${param}${PATH_PARAM_VALUE_DELIMITER}${params[param]}`)
    .join('/');

  const queryParams = new URLSearchParams(location?.query);

  QUERY_PARAMS.forEach((param) => {
    if (paramsList.includes(param)) {
      queryParams.set(param, params[param]!);
    } else {
      queryParams.delete(param);
    }
  });

  return [pathParams, queryParams.toString()]
    .filter((param) => !!param)
    .join('?');
};
