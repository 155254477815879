import { ClientSearchSDK, SearchDocumentType } from '@wix/client-search-sdk';
import { createSettingsClient } from '@wix/search-settings-client';
import { ControllerParams, ReportError } from '@wix/yoshi-flow-editor';

import { createSearchLocation } from './location';
import { Spec } from './specs';

export const extendConfig = (props: ControllerParams) => {
  const { flowAPI, controllerConfig } = props;
  const { appParams, wixCodeApi, setProps } = controllerConfig;
  const { instance } = appParams;
  const { reportError, environment, experiments } = flowAPI;
  const { isMobile, isSSR, isEditorX, isViewer, language } = environment;
  const siteBaseUrl = wixCodeApi.location.baseUrl;

  // NOTE: location.baseUrl is tricky in Editor environment, see:
  // https://wix.slack.com/archives/CAKBA7TDH/p1568384247066100
  const apiBaseUrl =
    isViewer && siteBaseUrl ? getUrlOrigin(siteBaseUrl, reportError) : '';

  const settingsClient = createSettingsClient({
    instance,
    apiBaseUrl,
    reportError,
  });

  const getCategoryList = async () => {
    try {
      const { categoryList } = isViewer
        ? await settingsClient.getPublished()
        : await settingsClient.getSaved();

      return categoryList;
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      return (await settingsClient.getDefault()).categoryList;
    }
  };

  const searchLocation = createSearchLocation(wixCodeApi);

  const buildSearchAllUrl = async (searchQuery: string) => {
    const absoluteUrl = await searchLocation.getSearchResultsAbsoluteUrl();

    const searchAllRelativeUrl = searchLocation.encodeParams({
      query: searchQuery,
    });
    return `${absoluteUrl}/${searchAllRelativeUrl}`;
  };

  const searchSDK = new ClientSearchSDK({
    apiBaseUrl,
    siteBaseUrl,
    language,
    timeout: 25000,
    token: instance,
    useEmptyImages: experiments.enabled(Spec.UseEmptyImages),
    ...(!experiments.enabled(Spec.OnlinePrograms) && {
      excludeDocumentTypes: [SearchDocumentType.Programs],
    }),
  });

  return {
    ...controllerConfig,
    experiments,
    getCategoryList,
    reportError,
    searchLocation,
    searchSDK,
    language,
    setProps,
    buildSearchAllUrl,
    isMobile,
    isSSR,
    isEditorX,
    isViewer,
    flowAPI,
  };
};

function getUrlOrigin(url: string, reportError: ReportError): string {
  try {
    return new URL(url).origin;
  } catch (e: any) {
    console.error(e); // eslint-disable-line no-console
    reportError(e);
    return '';
  }
}
