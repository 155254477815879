import {
  SearchDocumentType,
  SearchOrderingDirection,
  ISearchRequestOrdering,
} from '@wix/client-search-sdk';

import { NotTranslatedDropdownOption } from '../searchResultsControllerStore';

export enum SortOptionId {
  Default = 0,
  ForumLastActivityDateDesc = 1,
  ForumCreatedDateDesc = 2,
  ForumTotalCommentsDesc = 3,
  ForumViewCountDesc = 4,
  ForumLikeCountDesc = 5,
  EventsStartDateAsc = 8,
  BlogPublishDateDesc = 9,
  ProductsPriceAsc = 10,
  ProductsPriceDesc = 11,
}

enum ForumOrderingField {
  CreatedDate = 'createdDate',
  LastActivityDate = 'lastActivityDate',
  LikeCount = 'likeCount',
  TotalComments = 'totalComments',
  ViewCount = 'viewCount',
}

enum EventsOrderingField {
  StartDate = 'startDate',
}

enum BlogOrderingField {
  PublishDate = 'publishDate',
}

enum ProductsOrderingField {
  DiscountedPriceNumeric = 'discountedPriceNumeric',
}

const sortOptionConfig: Record<
  SortOptionId,
  {
    translation: string;
    ordering: ISearchRequestOrdering['ordering'];
  }
> = {
  [SortOptionId.Default]: {
    translation: 'searchResults.sort.options.default',
    ordering: [],
  },
  [SortOptionId.ForumLastActivityDateDesc]: {
    translation:
      'searchResults.sort.dropdownOptions.forum.lastActivityDate.desc',
    ordering: [
      {
        fieldName: ForumOrderingField.LastActivityDate,
        direction: SearchOrderingDirection.Descending,
      },
    ],
  },
  [SortOptionId.ForumCreatedDateDesc]: {
    translation: 'searchResults.sort.dropdownOptions.forum.createdDate.desc',
    ordering: [
      {
        fieldName: ForumOrderingField.CreatedDate,
        direction: SearchOrderingDirection.Descending,
      },
    ],
  },
  [SortOptionId.ForumLikeCountDesc]: {
    translation: 'searchResults.sort.dropdownOptions.forum.likeCount.desc',
    ordering: [
      {
        fieldName: ForumOrderingField.LikeCount,
        direction: SearchOrderingDirection.Descending,
      },
    ],
  },
  [SortOptionId.ForumTotalCommentsDesc]: {
    translation: 'searchResults.sort.dropdownOptions.forum.totalComments.desc',
    ordering: [
      {
        fieldName: ForumOrderingField.TotalComments,
        direction: SearchOrderingDirection.Descending,
      },
    ],
  },
  [SortOptionId.ForumViewCountDesc]: {
    translation: 'searchResults.sort.dropdownOptions.forum.viewCount.desc',
    ordering: [
      {
        fieldName: ForumOrderingField.ViewCount,
        direction: SearchOrderingDirection.Descending,
      },
    ],
  },
  [SortOptionId.EventsStartDateAsc]: {
    translation: 'searchResults.sort.dropdownOptions.events1.startDate.desc',
    ordering: [
      {
        fieldName: EventsOrderingField.StartDate,
        direction: SearchOrderingDirection.Ascending,
      },
    ],
  },
  [SortOptionId.BlogPublishDateDesc]: {
    translation: 'searchResults.sort.dropdownOptions.blog.publishDate.desc',
    ordering: [
      {
        fieldName: BlogOrderingField.PublishDate,
        direction: SearchOrderingDirection.Descending,
      },
    ],
  },
  [SortOptionId.ProductsPriceAsc]: {
    translation: 'searchResults.sort.dropdownOptions.products.price.asc',
    ordering: [
      {
        fieldName: ProductsOrderingField.DiscountedPriceNumeric,
        direction: SearchOrderingDirection.Ascending,
      },
    ],
  },
  [SortOptionId.ProductsPriceDesc]: {
    translation: 'searchResults.sort.dropdownOptions.products.price.desc',
    ordering: [
      {
        fieldName: ProductsOrderingField.DiscountedPriceNumeric,
        direction: SearchOrderingDirection.Descending,
      },
    ],
  },
};

const sortOptionsByDocumentType: Partial<
  Record<SearchDocumentType, SortOptionId[]>
> = {
  [SearchDocumentType.Forums]: [
    SortOptionId.Default,
    SortOptionId.ForumLastActivityDateDesc,
    SortOptionId.ForumCreatedDateDesc,
    SortOptionId.ForumTotalCommentsDesc,
    SortOptionId.ForumViewCountDesc,
    SortOptionId.ForumLikeCountDesc,
  ],
  [SearchDocumentType.Events]: [
    SortOptionId.Default,
    SortOptionId.EventsStartDateAsc,
  ],
  [SearchDocumentType.Blogs]: [
    SortOptionId.Default,
    SortOptionId.BlogPublishDateDesc,
  ],
  [SearchDocumentType.Products]: [
    SortOptionId.Default,
    SortOptionId.ProductsPriceAsc,
    SortOptionId.ProductsPriceDesc,
  ],
};

const defaultSortOptions = [SortOptionId.Default];

function buildSortDropdownOptions(sortOptions: SortOptionId[]) {
  return sortOptions.map((id) => {
    return {
      id,
      valueKey: sortOptionConfig[id].translation,
    };
  });
}

export function getSortOptions(
  documentType?: SearchDocumentType,
): NotTranslatedDropdownOption[] {
  const sortOptions = documentType
    ? sortOptionsByDocumentType[documentType]
    : undefined;

  return buildSortDropdownOptions(sortOptions ?? defaultSortOptions);
}

export function getOrdering(
  sortOptionId: SortOptionId,
): ISearchRequestOrdering {
  return {
    ordering: sortOptionConfig[sortOptionId].ordering || [],
  };
}

export function getDefaultSortOption() {
  return SortOptionId.Default;
}

export function isSortSupported(documentType: SearchDocumentType) {
  return documentType in sortOptionsByDocumentType;
}
