import {
  IWidgetController,
  IControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';
import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';
import { CategoryList } from '@wix/search-settings-client';

import {
  ISearchResultsWixCode,
  IWidgetControllerConfig,
} from '../../../../lib/platform.types';
import { SearchResultsControllerStore } from './searchResultsControllerStore';
import { extractSettings } from './extractSettings';
import { getPublicData } from './getPublicData';

interface ISettingsEvents {
  previewStateChange: {
    shouldHaveSearchResults: boolean;
    shouldSetNonAllDocumentType: boolean;
  };
  categoryListChange: CategoryList;
}

export async function searchResultsControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  const { config, reportError, getCategoryList, flowAPI } = controllerConfig;
  const state = {
    categoryList: await getCategoryList(),
  };
  const settings = extractSettings(flowAPI, state.categoryList);
  const publicData = getPublicData(config);
  const eventHandler = createEventHandler<ISettingsEvents>(publicData);
  let controllerStore: SearchResultsControllerStore;

  eventHandler.on(
    'previewStateChange',
    (value: ISettingsEvents['previewStateChange']) => {
      controllerStore.updateDemoMode(value);
    },
  );

  eventHandler.on('categoryListChange', (categoryList: CategoryList) => {
    state.categoryList = categoryList;
  });

  eventHandler.onReset(() => {
    controllerStore.updateDemoMode({
      shouldHaveSearchResults: true,
      shouldSetNonAllDocumentType: false,
    });
  });

  try {
    controllerStore = new SearchResultsControllerStore(
      controllerConfig,
      settings,
    );
  } catch (error: any) {
    reportError(error);
    throw error;
  }

  return {
    pageReady() {
      return controllerStore.setInitialState();
    },

    updateConfig($w, updatedConfig) {
      const updatedPublicData = getPublicData(
        updatedConfig as IControllerConfig,
      );

      // Trigger events
      eventHandler.notify(updatedPublicData);

      const updatedSettings = extractSettings(flowAPI, state.categoryList);
      controllerStore.updateSettings(updatedSettings);
    },

    exports: () =>
      ({
        // NOTE: used for mocks in tests
        searchSDK: controllerStore.getSearchSDK(),
        changeQuery: controllerStore.changeQuery,
      } as ISearchResultsWixCode),
  };
}
